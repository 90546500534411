<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">CLIENTS</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Edit Client</h4>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Client Name</label>
            <input
              v-model="client.name"
              type="text"
              class="form-control"
              placeholder="Enter Client Name"
            />
          </div>
          <div>
            <img :src="imageUrl" class="img-fluid w-25 d-inline-block" />
          </div>
          <div class="form-group d-inline-block">
            <label for="exampleFormControlFile1">Client Logo</label>
            <input
              @change="setImagePreview"
              ref="imageInput"
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
            />
          </div>

          <button @click.prevent="saveClient" class="btn btn-block btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "EditClient",
  data() {
    return {
      imageUrl: "/placeholder.png",
      client: {
        name: "",
        logo: "",
      },
    };
  },
  methods: {
    async setImagePreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageUrl = fr.result;
      };
      console.log(this.$refs.imageInput.files[0]);
      const src = fr.readAsDataURL(this.$refs.imageInput.files[0]);
    },
    async saveClient() {
      try {
        if (this.$refs.imageInput.files.length) {
          const fileName = await this.$store.dispatch(
            "uploadImage",
            this.$refs.imageInput.files[0]
          );

          this.client.logo = fileName;
        }

        const res = await axios.patch(
          "/api/v1/clients/" + this.client._id,
          this.client,
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status == 200) {
          this.$router.push("/clients");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },

    async getClient() {
      try {
        const res = await axios.get(`/api/v1/clients/${this.$route.params.id}`);
        if (res.status == 200) {
          this.client = res.data.client;
          this.imageUrl = `/uploads/${res.data.client.logo}`;
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
  },
  mounted() {
    this.getClient();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>